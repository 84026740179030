interface IRequestMessage {
  action: string;
  params: any;
  callbackId?: string;
}

interface IResponseMessage {
  callbackId: string;
  code: number;
  message: string;
  data: any;
}

interface IBridgeHandler {
  sendMessage: (message: IRequestMessage) => Promise<IResponseMessage> | void;
  onMessage: (handler: (messsage: IResponseMessage) => any) => void;
}

class InAppWebviewBridgeHandler implements IBridgeHandler {
  private _handler: (messsage: IResponseMessage) => any = () => {};

  sendMessage(message: IRequestMessage) {
    const action = window.flutter_inappwebview.callHandler(
      message.action,
      message,
    );
    action.then((res: any) => {
      this._handler(res);
    });
  }

  onMessage(handler: (messsage: IResponseMessage) => any) {
    this._handler = handler;
  }
}

class FlutterWebviewBridgeHandler implements IBridgeHandler {
  private _channel: any = {};
  constructor() {
    this._channel = window.smartdeer;
  }

  sendMessage(message: IRequestMessage) {
    this._channel.postMessage(JSON.stringify(message));
  }

  onMessage(handler: (messsage: IResponseMessage) => any) {
    window.smd_bridge = {};
    window.smd_bridge.receiveMessage = handler;
  }
}

class WebHandler implements IBridgeHandler {
  sendMessage(message: IRequestMessage) {
    return;
  }

  onMessage(handler: (messsage: IResponseMessage) => any) {
    return;
  }
}

export default class AppBridge {
  static _debugLevel: number = 0;

  public readonly isInFlutterWebview: boolean;
  public readonly isInAppWebview: boolean;
  public readonly appVersion: string;
  public readonly appBuildVersion: string;

  private _callback = new Map();
  private _requestQueue: IRequestMessage[] = [];
  private _bridgeHandler: IBridgeHandler;
  private _requestLock: boolean = false;

  static debug(message: string, color: string = 'black') {
    if (this._debugLevel > 0) {
      const div = document.createElement('div');
      div.innerHTML = message;
      div.style.color = color;
      div.style.wordBreak = 'break-all';
      div.style.padding = '4px 12px';
      div.style.lineHeight = '1.4';
      div.style.fontSize = '12px';
      document.body.appendChild(div);
    }
  }

  static generateCallbackId() {
    const now = new Date().getTime();
    const rand = Math.random() * (9999 - 1000) + 1000;
    const id = now * 10000 + rand;
    return id.toString(36);
  }

  constructor() {
    const userAgent = window.navigator.userAgent;
    this.isInFlutterWebview = userAgent.indexOf('isFromApp=1') > -1;
    this.isInAppWebview = userAgent.indexOf('SmartDeerApp') > -1;

    AppBridge.debug(`isInAppWebview: ${this.isInAppWebview}`);
    AppBridge.debug(`isInFlutterWebview: ${this.isInFlutterWebview}`);

    const match = userAgent.match(/SmartDeerApp\/([\d\.]+)+(\d+)/);
    this.appVersion = match ? match[1] : '';
    this.appBuildVersion = match ? match[2] : '';

    if (this.isInFlutterWebview)
      this._bridgeHandler = new FlutterWebviewBridgeHandler();
    else if (this.isInAppWebview)
      this._bridgeHandler = new InAppWebviewBridgeHandler();
    else this._bridgeHandler = new WebHandler();

    this._bridgeHandler.onMessage((message) => this.receiveMessage(message));
  }

  private async postMessage(message: IRequestMessage) {
    if (!this.isInAppWebview && !this.isInFlutterWebview) return null;
    this._requestQueue.push(message);
    if (!this._requestLock) {
      const nextMessage = this._requestQueue.shift();
      if (nextMessage) {
        return this.processRequest(nextMessage);
      }
    }
  }

  get isInApp() {
    return this.isInAppWebview || this.isInFlutterWebview;
  }

  private processRequest(message: IRequestMessage): Promise<any> {
    AppBridge.debug(`准备发送请求:${JSON.stringify(message)}`);
    return new Promise((resolve, reject) => {
      message.callbackId = AppBridge.generateCallbackId();
      this._callback.set(message.callbackId, { resolve, reject });
      AppBridge.debug(`[IAW]发送消息: ${JSON.stringify(message)}`, 'red');
      this._bridgeHandler.sendMessage(message);
      this._requestLock = true;
    });
  }

  private processResponse(message: IResponseMessage) {
    this._requestLock = false;

    try {
      const cb = this._callback.get(message.callbackId);
      // 如果没有cb则直接返回即可。
      if (!cb) return;
      if (message.code !== 0) {
        cb.reject(message);
      } else {
        cb.resolve(message);
        this._callback.delete(message.callbackId);
      }
    } catch (err: any) {
      AppBridge.debug(`异常: ${err.message}`, 'red');
    }
  }

  private receiveMessage(message: IResponseMessage) {
    try {
      AppBridge.debug(`收到消息:${JSON.stringify(message)}`, 'green');
      this.processResponse(message);
      if (this._requestQueue.length) {
        const nextMessage = this._requestQueue.shift();
        if (nextMessage) {
          this.processRequest(nextMessage);
        }
      }
    } catch (err: any) {
      AppBridge.debug(err.message);
    }
  }

  public setupFinish() {
    return this.postMessage({ action: 'setup_finish', params: null });
  }

  public tokenExpired() {
    return this.postMessage({ action: 'token_expired', params: null });
  }

  public wallectLogin(params: {
    address: string;
    sign: string;
    message: string;
  }) {
    return this.postMessage({ action: 'wallet_login', params: params });
  }

  public close() {
    return this.postMessage({ action: 'close', params: null });
  }

  public callWallet() {
    return this.postMessage({ action: 'call_wallet', params: null });
  }

  public fullScreenInput(params: {
    title: string;
    placeholder: string;
    maxlength: number;
    autofocus: boolean;
    text: string;
  }) {
    return this.postMessage({ action: 'full_screen_input', params: params });
  }

  public async positionShare(params: { positionId: number }) {
    const res = await this.postMessage({
      action: 'position_share',
      params: params,
    });
    if (res.code === 404) {
      // showDialog({ message: 'Please update app.' })
    }
    return res;
  }

  public userChangeBind() {
    return this.postMessage({ action: 'user_change_bind', params: null });
  }

  public openLink(url: string) {
    return this.postMessage({ action: 'open_url', params: { url } });
  }

  public getAppCookies() {
    return this.postMessage({ action: 'get_cookie', params: null });
  }

  public async share(params: {}) {
    return await this.postMessage({ action: 'share', params });
  }

  public setNavBar(params: {}) {
    return this.postMessage({ action: 'setNavBar', params });
  }

  public gotoPosition(positionId: string | number) {
    window.open(`client://linglupin/C/positionDetail?id=${positionId}`);
  }

  public gotoCompany(organizationId: string | number) {
    window.open(`client://linglupin/C/organization?id=${organizationId}`);
  }

  public gotoRecruiter(recruiterId: string | number) {
    window.open(`client://linglupin/C/recruitersDetail?id=${recruiterId}`);
  }

  public gotoTalent(talentId: string | number) {
    window.open(`client://linglupin/B/personDetail?accountId=${talentId}`);
  }
}
