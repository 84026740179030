import { use, useEffect, useMemo, useState } from "react"
import { create } from 'zustand'
import cookies from 'js-cookie'

const openInstallAppKey =
  process.env.NEXT_PUBLIC_BUILD_ENV === 'production'
    ? 'kpt6k2'
    : 'w7fllj'

interface OpenInstallStore {
  openInstall: any
}

const useStore = create<OpenInstallStore>((set) => ({
  openInstall: null,
  setOpenInstall: (openInstall: any) => set(() => ({ openInstall }))
}))

function useOpeninstall() {
  return useStore((state) => state.openInstall)
}

function initOpenInstall() {
  // 避免SSR
  if (typeof window === 'undefined') return

  // 避免没有openinstall实例
  if (!window.OpenInstall) return

  // 这里通过全局来获取渠道和sharecode
  // 有个隐性的问题是，必须Channel和ShareCode同时存在，才能正确获取到渠道

  var data = window.OpenInstall.parseUrlParams()

  const COOKIE_CHANNEL = 'U_CH'
  const COOKIE_SHARE_CODE = 'U_SHARE_CODE'
  const channel = data.channelCode || data._ch || cookies.get(COOKIE_CHANNEL) || ''
  const shareCode = data.shareCode || cookies.get(COOKIE_SHARE_CODE) || ''

  data.shareCode = shareCode

  new window.OpenInstall({
    channelCode: channel,
    appKey: openInstallAppKey,
    onready: function () {
      useStore.setState({ openInstall: this })
      window.openinstall = this
    }
  }, data)
}

initOpenInstall()

export default useOpeninstall