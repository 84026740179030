import channel, { Channel } from '@/utils/channel'
import { create } from "zustand"


interface ChannelStore {
  channel: Channel | null
}

const useStore = create<ChannelStore>((set) => ({
  channel: null,
  setChannel: (channel:Channel) => set(() => ({ channel }))
}))

function useChannel() {
  return useStore((state) => state.channel)
}

function initChannel() {
  // 环境判断，可以在这里修改key，或者避免上报数据
  const env = process.env.NEXT_PUBLIC_BUILD_ENV || ''
  // if(!['test', 'development'].includes(env)) return

  // 避免SSR
  if (typeof window === 'undefined') return
  // 避免没有openinstall实例
  if (!window.OpenInstall) return
  // 初始化channel
  channel.setChannel()
  useStore.setState({channel: channel.getChannel()})
}

initChannel()

export default useChannel