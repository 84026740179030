import { use, useEffect, useState } from "react"
import track from '@/utils/track'

function useTrack() {
  const [tracker, setTracker] = useState<any>(null)

  useEffect(() => {
    setTracker(track)
  }, [])

  return tracker
}

export default useTrack