import queryString from 'query-string';

const TRACE: { [key: string]: any } = {};
const REG_UNDERLINE = /^_/;
const APP_DOMAIN = 'client://linglupin';
const ROUTERS: { [key: string]: any } = {};

const ON_EVENT: any = {
  toLoading: () => {},
};

/**
 * 超链接单击的事件侦听器
 * */
function addClickListener() {
  document.body.removeEventListener('click', handleClick);
  document.body.addEventListener('click', handleClick);
}

/**
 * 在第一个打开的页面中缓存 REG_UNDERLINE 匹配的参数
 * @param { string } link asPath
 * */
export function cacheTraceParams(link: string) {
  const params: { [key: string]: any } = {};
  const url = queryString.parseUrl(link);
  Object.entries(url.query).forEach((item) => {
    const [key, value] = item;
    if (REG_UNDERLINE.test(key)) {
      params[key] = value;
    }
  });

  return Object.assign(TRACE, params);
}

/**
 * 重建路由，并使用 REG_UNDERLINE 匹配的参数返回
 * @param { string } link path
 * @returns {string} 具有跟踪参数的重建路由
 * */
export function getTraceLink(link: string): string {
  return queryString.stringifyUrl({ url: link, query: TRACE });
}

/**
 * 检索父锚点元素
 * @param { any } element 当前元素
 * @param { string } target 目标元素
 * @returns {HTMLElement|null} 父锚点元素或null（如果未找到）
 * */
export function getParentAnchor(
  element: any,
  target: string,
): HTMLElement | null {
  try {
    while (element !== null) {
      // 判断是否匹配目标元素
      if (element.tagName.toUpperCase() === target) {
        return element;
      }

      element = element.parentNode;
    }
    return null;
  } catch (err) {
    return null;
  }
}

/**
 * 通过将 key 与路径匹配来获取路由参数
 * @param { string } key 路线字典中的关键字
 * @param { string } path 页面 path
 * @returns {Record<string, string>} 匹配的路由参数
 * */
export function getRouteParams(
  key: string,
  path: string,
): Record<string, string> {
  const pattern: RegExp = new RegExp(key.replace(/:\w+/g, '(\\w+)'));
  const match = path.match(pattern);

  const matches = key.match(/\/:(\w+)/g);
  if (!matches || !match || !match[1]) return {};

  const params: { [key: string]: any } = {};

  matches.forEach((item, index) => {
    const k = item.slice(2);
    params[k] = match[index + 1];
  });

  return params;
}

/**
 * 返回带有路由参数的 app 页面地址
 * @param { string } key 路由字典中的关键字
 * @param { string } query 路由参数
 * @returns {string} 带有路由参数的应用程序页面地址
 * */
export function getAppRoute(key: string, query: any): string {
  const arg = JSON.parse(JSON.stringify(ROUTERS[key].arguments));

  Object.keys(arg).forEach((k: string) => {
    arg[k] = query[arg[k]];
  });

  const newLink = queryString.stringifyUrl({
    url: ROUTERS[key].path,
    query: arg,
  });

  return APP_DOMAIN + newLink;
}

/**
 * 将链接映射到路线
 * 这段代码实现了一个将链接映射到路由的功能。首先获取路由字典中的所有 key，
 * 然后遍历这些 key，通过正则表达式匹配链接中的 path，如果匹配成功，
 * 则获取路由参数并将其与链接中的 query 参数合并，最后调用 getAppRoute
 * 函数生成新的路由链接。如果没有匹配成功，则返回 null。
 *
 * @param {string} link 链接
 * @returns {string|null} 映射的路由或null（如果未找到）
 * */
export function mapLinkToRoute(link: string): string | null {
  if (JSON.stringify(ROUTERS) === '{}') return null;

  const path = link.includes('?') ? link.split('?')[0] : link;

  for (const [key, value] of Object.entries(ROUTERS)) {
    const regKey: RegExp = new RegExp(`^${key.replace(/:\w+/g, '\\w+')}$`); // 构建正则表达式
    const match = path.match(regKey); // 匹配路径

    if (match) {
      const params = getRouteParams(key, path);

      const query = {
        ...queryString.parseUrl(link).query,
        ...params,
      };

      return getAppRoute(key, query);
    }
  }

  return null;
}

/**
 *  获取跳转 Path
 * */
export function getPath(e: Event): string {
  const target = e.target || e.srcElement; // 兼容处理

  // 捕获 a 标签
  const anchor = getParentAnchor(target, 'A');

  if (!anchor) return '';

  // 对捕获到的 a 标签进行处理

  // 阻止默认事件
  e.preventDefault && e.preventDefault();

  // 获取 a 标签的 href 属性
  let href = anchor.getAttribute('href');

  if (!href) {
    console.error('a 标签上不存在 href 属性！');
    return '';
  }

  if (href.includes('.work')) {
    href = href.split('.work')[1];
  }

  if (href.includes('.com')) {
    href = href.split('.com')[1];
  }

  // 判断是否匹配到app路由
  const appUrl = mapLinkToRoute(href);

  // 返回 path
  return appUrl ? appUrl : getTraceLink(href);
}

/**
 * 处理点击事件的逻辑
 * @param {Event} e 点击事件
 * */
function handleClick(e: Event) {
  const path = getPath(e);

  if (!path) return;

  ON_EVENT['toLoading']();

  // 路由跳转
  window.location.href = path;
}

export function handleHyperLinkTo(href: string) {
  if (!href) {
    console.error('不存在 href ！');
    return '';
  }

  if (href.includes('.work')) {
    href = href.split('.work')[1];
  }

  if (href.includes('.com')) {
    href = href.split('.com')[1];
  }

  // 判断是否匹配到app路由
  const appUrl = mapLinkToRoute(href);

  const path = appUrl ? appUrl : getTraceLink(href);

  ON_EVENT['toLoading']();

  // 路由跳转
  window.location.href = path;
}

interface TAppRoute {
  [propName: string]: any;
}

type THyperlinkOptions = {
  isListener?: boolean;
  isTrace?: boolean;
  isAppRouteMapping?: boolean;
  appRoute: TAppRoute;
  appVersion: string;
  toLoading?: () => void;
};

/**
 * 超链接实用程序功能
 * @param {THyperlinkOptions} options - hyperlink options
 */
export default function hyperlink(options: THyperlinkOptions) {
  const {
    isListener = true,
    isTrace = true,
    isAppRouteMapping = false,
    appRoute,
    appVersion,
    toLoading,
  } = options;

  // 存在 toLoading 方法，并且不是 app 环境
  if (toLoading && !isAppRouteMapping) {
    ON_EVENT['toLoading'] = toLoading;
  }

  if (isListener) {
    addClickListener();
  }

  if (isTrace) {
    cacheTraceParams(window.location.href);
  }

  if (isAppRouteMapping) {
    const vRoute = getVersionRoute(appVersion, appRoute);
    Object.assign(ROUTERS, vRoute);
  }
}

/**
 * 根据版本号，获取对应的 app 路由表
 * @param {string} appVersion - app 版本号
 * @param {TAppRoute} appRoute - hyperlink options
 * */
export function getVersionRoute(appVersion: string, appRoute: TAppRoute) {
  let curVersion = appVersion;

  if (!curVersion) return {};

  curVersion = curVersion.split('+')[0];

  if (appRoute[curVersion]) {
    return appRoute[curVersion];
  } else {
    const keys = Object.keys(appRoute);
    const index = getVersionIndex(keys, curVersion);
    return appRoute[keys[index]];
  }
}

/**
 * 检索列表中版本的索引
 * @param {string[]} list 版本列表
 * @param {string} version 当前版本
 * @returns {number} 版本的索引
 */
function getVersionIndex(list: string[], version: string): number {
  const index = list.findIndex((v) => {
    const vArr = v.split('.');
    const newVersionArr = version.split('.');
    for (let i = 0; i < 3; i++) {
      const vNum = parseInt(vArr[i]);
      const newVersionNum = parseInt(newVersionArr[i]);
      if (vNum !== newVersionNum) {
        return vNum > newVersionNum;
      }
    }
    return false;
  });
  return index === -1 ? list.length - 1 : index - 1;
}
