import style from '@/components/subject/global-jobs/svg-loading.module.sass'

export default function SvgLoading(props: {size: string}) {
  const {size} = props
  return <div style={{width: size, height: size}}>
    <svg viewBox="0 0 32 32" className={style['loading-icon']}>
      <circle
        fill="transparent"
        stroke="#FF812A"
        strokeWidth="4"
        strokeDasharray="94.2477796076937"
        strokeDashoffset="48.77916666741172"
        strokeLinecap="round" r="13" cx="16" cy="16">
      </circle>
    </svg>
  </div>
}