import React, { ReactElement, ReactNode, useEffect, } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import hyperlink from '@/utils/hyperlink';
import { APP_ROUTE, APP_VERSION } from '@/consts';
import { useAppBridge } from '@/hooks';
import Cookies from 'js-cookie';
import { Toast } from 'antd-mobile';
import SvgLoading from "@/components/subject/global-jobs/svg-loading"
import { createRoot } from 'react-dom/client';

import '@/styles/globals.css';
import useChannel from '@/hooks/useChannel';

const App = ({ Component, pageProps, router }: AppProps) => {

  // 这里触发一次channel的初始化，保证全区有channel相关的cookie
  const channel = useChannel()
  // 初始化桥
  const bridge = useAppBridge()

  // 初始化 Hyperlink 方法
  const _initHyperlink = async () => {
    // app 版本号
    let appVersion = '';
    if (bridge?.isInApp) {
      appVersion = Cookies.get(APP_VERSION) || bridge?.appVersion || ''

      // 版本号为空，再次通过桥获取版本号
      if (!appVersion) {
        const res: any = await bridge?.getAppCookies();
        appVersion = res.data[APP_VERSION];
      }
    }

    hyperlink({
      appRoute: APP_ROUTE,
      appVersion: appVersion || '',
      isAppRouteMapping: bridge?.isInApp,
      toLoading: () => {
        const root = document.createElement('div')
        root.style.position = 'fixed'
        root.style.top = '0'
        root.style.left = '0'
        root.style.width = '100vw'
        root.style.height = '100vh'
        root.style.display = 'flex'
        root.style.justifyContent = 'center'
        root.style.alignItems = 'center'
        root.style.zIndex = '9999'
        document.body.appendChild(root)
        const loading = React.createElement(SvgLoading, { size: '1rem' })
        createRoot(root).render(loading)

        setTimeout(() => {
          document.body.removeChild(root)
        }, 1000)

        // Toast.show({
        //   content: <SvgLoading size={'1rem'}></SvgLoading>,
        //   maskClickable: false,
        //   duration: 2000,
        // })
      }
    })
  }

  useEffect(() => {
    _initHyperlink()
  }, [bridge])

  return <Component {...pageProps} />
};

export default appWithTranslation(App as any);
