import querystring from 'query-string';
import cookies from 'js-cookie';
import { UAParser } from 'ua-parser-js';
import request from 'axios';
import { v4 as uuidv4 } from 'uuid';

const SDK_SESSION_STORAGE_KEY = 'U_SESSION';
const ACCOUNT_ID_STORAGE_KEY = 'U_ID';
const PLATFORM = '3';
const PROJECT = 'MKT_H5'

const API_HOST =
  process.env.NEXT_PUBLIC_BUILD_ENV === 'production'
    ? `https://api.smartdeer.com`
    : `https://global-test.smartdeer.work`;

function getDomain() {
  switch (process.env.NEXT_PUBLIC_BUILD_ENV) {
    case 'production':
      return '.smartdeer.com';
    case 'test':
      return '.smartdeer.work';
    default:
      return '';
  }
}

const COOKIE_DOMAIN = getDomain();

const API_ADDRESS = '/api/v1/common/action/log'

if (typeof window !== 'undefined') {
  // init session 
  const session = cookies.get(SDK_SESSION_STORAGE_KEY)
  if (!session || session === 'undefined') {
    cookies.set(SDK_SESSION_STORAGE_KEY, uuidv4(), { expires: 365, domain: COOKIE_DOMAIN });
  }
}

function generateTraceId() {
  return Math.floor(Math.random() * 65535 * 1000000).toString(36)
}

function getUriPath(uri: string) {
  const path = new URL(uri, 'http://www.host.com').pathname;
  return path;
}


// TODO: 后续需要将track修改为同时支持client和server端渲染而不是分开两个
/**
 * 页面PV统计
 * @param url 要统计的页面
 * @param ext 拓展信息
 *
 * 该方法将会自动统计上一个页面，作为referer放在打点信息中
 */
function pageview(
  pagename: string,
  ext: any = {},
  callback = (err: any, res: any) => {
    return;
  },
) {
  const params: any = querystring.parse(location.search);
  const path = getUriPath(location.href);

  const parser = new UAParser(window.navigator.userAgent);
  const info = parser.getResult();

  const utms = {
    utm_source: params.utm_source || null,
    utm_medium: params.utm_medium || null,
    utm_term: params.utm_term || null,
    utm_content: params.utm_content || null,
    utm_campaign: params.utm_campaign || null,
    referral: document.referrer || null,
  };

  const extra = {
    language: window.navigator.language,
    device_type: info.device.type!,
    device_model: info.device.model!,
    os: info.os.name!,
    os_ver: info.os.version!,
    browser_name: info.browser.name!,
    browser_ver: info.browser.version!,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    platform: PLATFORM,
    event: 'page_view',
  };

  const event = {
    app: PROJECT,
    uuid: cookies.get(SDK_SESSION_STORAGE_KEY),
    url: location.href,
    accountId: parseInt(cookies.get(ACCOUNT_ID_STORAGE_KEY)!, 10) || undefined,
    pageName: pagename,
    platform: PLATFORM,
  };

  const data = Object.assign({}, event, {
    extra: Object.assign({}, extra, utms, ext),
  });

  sendEvent(data, callback);
}

/**
 * 事件打点
 * @param event_id 事件ID
 * @param ext 拓展信息
 */
function event(
  event_id: string,
  ext: any = {},
  callback = (err: any, res: any) => {
    return;
  },
) {
  const params: any = querystring.parse(location.search);
  const path = getUriPath(location.href);

  const parser = new UAParser(window.navigator.userAgent);
  const info = parser.getResult();

  const utms = {
    utm_source: params.utm_source || null,
    utm_medium: params.utm_medium || null,
    utm_term: params.utm_term || null,
    utm_content: params.utm_content || null,
    utm_campaign: params.utm_campaign || null,
    referral: document.referrer || null,
  };

  const extra = {
    language: window.navigator.language,
    device_type: info.device.type!,
    device_model: info.device.model!,
    os: info.os.name!,
    os_ver: info.os.version!,
    browser_name: info.browser.name!,
    browser_ver: info.browser.version!,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    platform: PLATFORM,
    event: 'page_event',
    event_id: event_id,
  };

  const event = {
    app: PROJECT,
    uuid: cookies.get(SDK_SESSION_STORAGE_KEY),
    url: location.href,
    accountId: parseInt(cookies.get(ACCOUNT_ID_STORAGE_KEY)!, 10) || undefined,
    platform: PLATFORM,
  };

  const data = Object.assign({}, event, {
    extra: Object.assign({}, extra, utms, ext),
  });

  sendEvent(data, callback);
}

/**
 * 点击事件
 * @param click_id 点击ID
 * @param ext 拓展信息
 */
function click(
  click_id: string,
  ext: any = {},
  callback = (err: any, res: any) => {
    return;
  },
) {
  const params: any = querystring.parse(location.search);
  const path = getUriPath(location.href);

  const parser = new UAParser(window.navigator.userAgent);
  const info = parser.getResult();

  const utms = {
    utm_source: params.utm_source || null,
    utm_medium: params.utm_medium || null,
    utm_term: params.utm_term || null,
    utm_content: params.utm_content || null,
    utm_campaign: params.utm_campaign || null,
    referral: document.referrer || null,
  };

  const extra = {
    language: window.navigator.language,
    device_type: info.device.type!,
    device_model: info.device.model!,
    os: info.os.name!,
    os_ver: info.os.version!,
    browser_name: info.browser.name!,
    browser_ver: info.browser.version!,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    platform: PLATFORM,
    event: 'page_click',
    event_id: click_id,
  };

  const event = {
    app: PROJECT,
    uuid: cookies.get(SDK_SESSION_STORAGE_KEY),
    url: location.href,
    accountId: parseInt(cookies.get(ACCOUNT_ID_STORAGE_KEY)!, 10) || undefined,
    platform: PLATFORM,
  };

  const data = Object.assign({}, event, {
    extra: Object.assign({}, extra, utms, ext),
  });

  sendEvent(data, callback);
}

/**
 *
 * @param event 事件数据结构
 * @param config 配置数据
 * @param data 事件结构数据
 */
function sendEvent(event: any, callback: (err: any, res: any) => void) {
  request
    .post(`${API_HOST}${API_ADDRESS}`, event)
    .then(() => { })
    .catch((err) => { });
}

export default {
  click,
  event,
  pageview,
};
