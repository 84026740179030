import { useEffect, useMemo, useState } from "react"
import AppBridge from "@/utils/app-bridge"
import { create } from "zustand"

interface AppBridgeStore {
  appBridge: AppBridge | null
}

function useAppBridge() {
  return useStore((state) => state.appBridge)
}

const useStore = create<AppBridgeStore>((set) => ({
  appBridge: null,
  setAppBridge: (appBridge: AppBridge) => set(() => ({ appBridge }))
}))

function initAppBridge() {
  // 环境判断，可以在这里修改key，或者避免上报数据
  const env = process.env.NEXT_PUBLIC_BUILD_ENV || ''
  // if(!['test', 'development'].includes(env)) return

  // 避免SSR
  if (typeof window === 'undefined') return

  const appBridge = new AppBridge()
  useStore.setState({ appBridge })
}

initAppBridge()

export default useAppBridge