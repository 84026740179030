export const APP_ROUTE = {
  '1.0.5': {
    '/job/:id': {
      path: '/C/positionDetail', // 职位详情
      arguments: {
        id: 'id', // 这里只需映射不同的key
      },
    },
    '/project/:id': {
      path: '/C/organization', // 公司详情
      arguments: {
        id: 'id', // 这里只需映射不同的key
      },
    },
    '/recruiter/:id': {
      path: '/C/recruitersDetail', // 招聘人详情
      arguments: {
        id: 'id', // 这里只需映射不同的key
      },
    },
  },
};

export const OPEN_APP_URL = 'https://universe.smartdeer.com/app/';

export const APP_VERSION = 'X-CLIENT-VERSION';

export const WX_APP_ID = 'wxb6dd67c95ed548b2';

export const WEB3_EVENT_ID = '666';

export const WEB3_EVENT_OPERATE = {
  project: {
    type: 1,
    score: 1000,
  },
  job: {
    type: 2,
    score: 1000,
  },
  recruiter: {
    type: 3,
    score: 1000,
  },
  click: {
    type: 4,
    score: 1000,
  },
  moments: {
    type: 5,
    score: 5000,
  },
  friends: {
    type: 6,
    score: 3000,
  },
  download: {
    type: 7,
    score: 8000,
  },
};
