import { type } from 'os';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

const useScrollTop = () => {
  const initScrollTo = () => {
    try {
      // 获取网页的加载来源
      // const type = window.performance.navigation.type;

      // // type === 2 是会退页面
      // if (type === 2) return;

      // 当前页面滚动条位置
      const Y = Cookies.get(location.pathname);

      if (Y) {
        // 初始化滚动条位置
        window.scrollTo(0, Number(Y));
      }
    } catch (err) {}
  };

  const handleBeforeunload = () => {
    try {
      const scrollY = window.scrollY + '';
      // 30分钟过期
      const in30Minutes = 1 / 48;

      // 换成当前页面滚动条位置
      Cookies.set(location.pathname, scrollY, {
        expires: in30Minutes,
      });
    } catch (err) {}
  };

  useEffect(() => {
    // initScrollTo();
    // // document.body.removeEventListener('click', handleBeforeunload);
    // // document.body.addEventListener('click', handleBeforeunload);
    // // 监听页面离开
    // window.onbeforeunload = handleBeforeunload;
    // // document.body.addEventListener('beforeunload', handleBeforeunload);
    // return () => {
    //   // 取消监听
    //   document.body.removeEventListener('beforeunload', handleBeforeunload);
    // };
  }, []);
};

export default useScrollTop;
