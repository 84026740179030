import cookies from 'js-cookie';
import queryString from 'query-string';

export interface Channel {
  channel: string;
  shareCode: string;
  extra: {
    _u?: string;
    _ch?: string;
    _cht?: string;
    _chu?: string;
  };
}

const COOKIE_CHANNEL_EXTRA = 'U_CH_EXTRA';
const COOKIE_CHANNEL = 'U_CH';
const COOKIE_SHARE_CODE = 'U_SHARE_CODE';

function getDomain() {
  switch (process.env.NEXT_PUBLIC_BUILD_ENV) {
    case 'production':
      return '.smartdeer.com';
    case 'test':
      return '.smartdeer.work';
    default:
      return '';
  }
}
const COOKIE_DOMAIN = getDomain();

function setChannel() {
  try {
    const query = queryString.parse(window.location.search);

    // channelCode是openinstall的渠道码 该码的优先级最高, _ch是自定义的渠道码。
    const channel = query.channelCode || query._ch;
    const shareCode = query.shareCode || '';

    if (channel) {
      cookies.set(COOKIE_SHARE_CODE, shareCode, {
        expires: 30,
        domain: COOKIE_DOMAIN,
      });
      cookies.set(COOKIE_CHANNEL, channel, {
        expires: 30,
        domain: COOKIE_DOMAIN,
      });
      cookies.set(COOKIE_CHANNEL_EXTRA, JSON.stringify(query), {
        expires: 30,
        domain: COOKIE_DOMAIN,
      });
    }
  } catch (err: any) {}
}

function getChannel(): Channel {
  try {
    const extra = JSON.parse(cookies.get(COOKIE_CHANNEL_EXTRA) || '{}');
    return {
      channel: cookies.get(COOKIE_CHANNEL) || '',
      shareCode: cookies.get(COOKIE_SHARE_CODE) || '',
      extra: {
        _u: extra._u || '',
        _ch: extra._ch || '',
        _cht: extra._cht || '',
        _chu: extra._chu || extra._u || '',
      },
    };
  } catch (err: any) {
    return {
      channel: cookies.get(COOKIE_CHANNEL) || '',
      shareCode: cookies.get(COOKIE_SHARE_CODE) || '',
      extra: {},
    };
  }
}

export default {
  setChannel,
  getChannel,
};
