var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e4439881-f68b-4865-bf1b-9195a7e28f5b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/global/mkt";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://8777011d8eea49fea1113ba8266232cc@sentry.corp.smartdeer.com/2",
  tracesSampleRate: 1.0,
  release: process.env.NEXT_PUBLIC_BUILD_ID,
  environment: process.env.NEXT_PUBLIC_BUILD_ENV,
});